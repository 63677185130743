import { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../assets/images/cross-ic.svg';
import { Place } from '../types';
import PlaceImage from './PlaceImage';
import './PlaceModal.css';

export type PlaceModalProps = {
  place: Place;
  visible?: boolean;
  onRequestClose?: () => void;
};

const PlaceModal = ({ place, visible = false, onRequestClose }: PlaceModalProps) => {
  const directionsUrl = useMemo(() => `https://www.google.com/maps/place/?q=place_id:${place.placeId}`, [place]);
  const websiteText = useMemo(() => (place.website ? new URL(place.website).hostname : ''), [place.website]);

  const openHours = useMemo(() => {
    if (!place.openingHours) {
      return [];
    }

    const hours = place.openingHours.map(v => v.split(': '));

    return hours;
  }, [place.openingHours]);

  const onAddressClick = useCallback(() => window.open(directionsUrl, '_blank'), [directionsUrl]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        onRequestClose?.();
      }
    },
    [onRequestClose]
  );

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onRequestClose}
      className="place-modal"
      overlayClassName="place-modal-overlay"
      contentLabel="Disposal Location"
      ariaHideApp={false}>
      <div className="place-modal-container">
        <div className="place-modal-close-button" onClick={onRequestClose} onKeyDown={onKeyDown}>
          <img src={CloseIcon} alt="Close" />
        </div>
        <PlaceImage className="place-modal-place-image" place={place} />
        <div className="place-modal-content">
          <h2>{place?.name}</h2>
          <div className="place-modal-details-container">
            <div className="place-modal-details-column">
              {!!place?.address && (
                <div className="place-modal-details-section">
                  <h4>Address</h4>
                  <a href={directionsUrl} target="_blank" rel="noreferrer">
                    <p>{place.address}</p>
                  </a>
                  <button onClick={onAddressClick}>Directions</button>
                </div>
              )}
              {!!place?.phone && (
                <div className="place-modal-details-section">
                  <h4>Phone</h4>
                  <a href={'tel:' + place.phone}>{place.phone}</a>
                </div>
              )}
              {!!place?.website && (
                <div className="place-modal-details-section">
                  <h4>Website</h4>
                  <a href={place.website} target="_blank" rel="noreferrer">
                    {websiteText}
                  </a>
                </div>
              )}
            </div>
            {!!place?.openingHours && (
              <div className="place-modal-details-column">
                <div className="place-modal-details-section">
                  <h4>Open hours</h4>
                  <table className="place-modal-hours-table">
                    <tbody>
                      {openHours.map(([day, hours]) => (
                        <tr key={day}>
                          <td>{day}</td>
                          <td>{hours}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PlaceModal;
