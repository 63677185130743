import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Layer, MapLayerMouseEvent, MapRef, default as Mapbox, Source } from 'react-map-gl';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import Key from '../components/Key';
import PlaceModal from '../components/PlaceModal';
import Popup from '../components/Popup';
import { ClusterFeature, Place, PlaceFeature } from '../types';
import { getMap } from '../utils/api';
import { adjustPlaceCoordinates } from '../utils/helpers';
import './Map.css';

// Mapbox have compatibility issues when transpiling with webpack
// for this reason we need to use worker-loader to load the worker
// @ts-ignore
mapboxgl.workerUrl = 'mapbox-gl-csp-worker.js';

const DEFAULT_BOUNDS: LngLatBoundsLike = [
  [113.338953078, -43.6345972634],
  [153.569469029, -10.6681857235],
];

const MIN_DISTANCE_METRES = 20;

const isPlaceFeature = (feature: mapboxgl.MapboxGeoJSONFeature): feature is PlaceFeature =>
  feature?.layer.id === 'places';

const isClusterFeature = (feature: mapboxgl.MapboxGeoJSONFeature): feature is ClusterFeature =>
  feature?.layer.id === 'clusters';

const getIconType = (type?: string) => {
  switch (type) {
    case 'Waste Facility':
    case 'Commercial Recycler':
      return 'recycle-station';
    default:
      return type?.toLowerCase().replace(/ /g, '-');
  }
};

const MapView = () => {
  const mapId = window.location.pathname.replace('/', '');

  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [features, setFeatures] = useState<GeoJSON.Feature[]>([]);
  const [hoveredFeature, setHoveredFeature] = useState<PlaceFeature>();
  const [mapBounds, setMapBounds] = useState<LngLatBoundsLike>(DEFAULT_BOUNDS);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mouseOverFeature, setMouseOverFeature] = useState(false);
  const [mouseOverPopup, setMouseOverPopup] = useState(false);
  const [isSiteletMode, setIsSiteletMode] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [showKey, setShowKey] = useState<boolean>();
  const [siteletId, setSiteletId] = useState<string>();

  const mapRef = useRef<MapRef>(null);

  const onLogoClick = useCallback(() => {
    window.open('https://recyclemate.com.au', '_blank');
  }, []);

  const setCursor = useCallback((cursor: string) => {
    const canvas = mapRef.current?.getCanvas();

    if (!canvas) {
      return;
    }

    canvas.style.cursor = cursor;
  }, []);

  const getPlaceForFeature = useCallback(
    (feature: PlaceFeature) =>
      features.find(f => f.properties?.id === feature.properties.id)?.properties as Place | undefined,
    [features],
  );

  const handleClusterClick = useCallback((feature: ClusterFeature) => {
    if (!mapRef.current) {
      return;
    }

    const clusterId = feature.properties.cluster_id;
    const geometry = feature.geometry;

    const source = mapRef.current.getSource('places') as mapboxgl.GeoJSONSource;

    source.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      mapRef.current?.easeTo({
        center: geometry.coordinates as [number, number],
        zoom: zoom,
      });
    });
  }, []);

  const onMapLoad = useCallback(() => {
    setMapLoaded(true);
  }, []);

  const onMapClick = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!mapRef.current || !e.features?.length) {
        return;
      }

      const feature = e.features[0];

      if (isClusterFeature(feature)) {
        handleClusterClick(feature);
      } else if (isPlaceFeature(feature)) {
        setSelectedPlace(getPlaceForFeature(feature));
      }
    },
    [getPlaceForFeature, handleClusterClick],
  );

  const onMapMouseEnter = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!mapRef.current || !e.features?.length) {
        return;
      }

      setCursor('pointer');

      if (isPlaceFeature(e.features[0])) {
        setHoveredFeature(e.features[0]);
        setMouseOverFeature(true);
      }
    },
    [setCursor],
  );

  const onMapMouseLeave = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!mapRef.current || !e.features?.length) {
        return;
      }

      setCursor('grab');

      const feature = e.features[0];

      if (isPlaceFeature(feature)) {
        setMouseOverFeature(false);
      }
    },
    [setCursor],
  );

  const onMapMouseOver = useCallback(() => {
    setMouseOverPopup(false);
  }, []);

  const onPopupMouseEnter = useCallback(() => {
    setMouseOverPopup(true);
  }, []);

  const onPlaceModalClose = useCallback(() => {
    setSelectedPlace(undefined);
  }, []);

  const onPopupClick = useCallback(
    (feature: PlaceFeature) => {
      setSelectedPlace(getPlaceForFeature(feature));
    },
    [getPlaceForFeature],
  );

  const loadData = useCallback(async () => {
    if (!mapId) {
      return;
    }

    const data = await getMap(mapId).then(data => ({
      ...data,
      wasteDestinations: adjustPlaceCoordinates(
        data.wasteDestinations.filter(v => v.location?.coordinates),
        MIN_DISTANCE_METRES,
      ),
    }));

    setShowKey(data.enableKey);

    const iconSources: Record<string, string | undefined> = {};

    if (data.itemIcons?.length) {
      data.wasteDestinations.forEach(place => {
        const placeIcons = data.itemIcons?.filter(icon => place.itemIcons?.includes(icon._id));

        iconSources[place.id] = placeIcons
          ?.map(icon => `${icon.name}~data:${icon.type};base64,${icon.base64}`)
          .join('|');
      });
    }

    setFeatures(
      data.wasteDestinations.map(place => ({
        type: 'Feature',
        geometry: place.location,
        properties: {
          ...place,
          iconType: getIconType(place.type),
          itemIcons: iconSources[place.id],
        } as PlaceFeature['properties'],
      })),
    );

    setMapBounds(data.bounds.coordinates);
  }, [mapId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (mapLoaded) {
      mapRef.current?.fitBounds(mapBounds);
    }
  }, [mapBounds, mapLoaded]);

  useEffect(() => {
    if (!mouseOverFeature) {
      const timeout = setTimeout(() => {
        if (!mouseOverPopup) {
          setHoveredFeature(undefined);
        }
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [mouseOverFeature, mouseOverPopup]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    setIsSiteletMode(queryParams.get('mode') === 'sitelet');
    setShowLogo(!queryParams.get('hideLogo'));
    setSiteletId(queryParams.get('siteletId') ?? undefined);

    if (queryParams.get('hideKey') !== null) {
      setShowKey(!queryParams.get('hideKey'));
    }
  }, []);

  return (
    <div className="map-container">
      <Mapbox
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
        initialViewState={{ bounds: DEFAULT_BOUNDS }}
        interactiveLayerIds={['clusters', 'places']}
        onClick={onMapClick}
        onMouseEnter={onMapMouseEnter}
        onMouseLeave={onMapMouseLeave}
        onMouseOver={onMapMouseOver}
        onLoad={onMapLoad}>
        <Source id="places" type="geojson" data={{ type: 'FeatureCollection', features }} cluster>
          <Layer
            id="clusters"
            type="circle"
            filter={['has', 'point_count']}
            paint={{
              'circle-color': '#ded4c5',
              'circle-radius': ['step', ['get', 'point_count'], 22, 100, 30, 750, 40],
            }}
          />
          <Layer
            id="cluster-count"
            type="symbol"
            filter={['has', 'point_count']}
            layout={{
              'text-field': ['get', 'point_count_abbreviated'],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            }}
          />
          <Layer
            id="places"
            type="symbol"
            filter={['!', ['has', 'point_count']]}
            layout={{
              'icon-image': ['get', 'iconType'],
              'icon-size': 0.35,
            }}
          />
        </Source>
        {!!hoveredFeature && (
          <Popup feature={hoveredFeature} onTitleClick={onPopupClick} onMouseEnter={onPopupMouseEnter} />
        )}
      </Mapbox>
      {(showKey ?? true) && (
        <div className="map-key-container">
          {showLogo && <Logo onClick={onLogoClick} />}
          <Key />
        </div>
      )}
      {!!selectedPlace && <PlaceModal place={selectedPlace} visible onRequestClose={onPlaceModalClose} />}
    </div>
  );
};

export default MapView;
