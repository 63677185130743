import { Place } from '../types';

export const haversineDistance = (point1: [number, number], point2: [number, number]) => {
  const R = 6371000; // Earth radius in meters
  const dLat = ((point2[1] - point1[1]) * Math.PI) / 180;
  const dLon = ((point2[0] - point1[0]) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((point1[1] * Math.PI) / 180) *
      Math.cos((point2[1] * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

/**
 * Ensures that no two places are closer than `minDistance` meters apart. Applies a small random offset so that places
 * with identical coordinates are clustered around a point instead of spread in a straight line.
 */
export const adjustPlaceCoordinates = (places: Place[], minDistance: number) => {
  const R = 6371000; // Earth radius in meters
  const adjustedPlaces: Place[] = [];

  places.forEach(place => {
    const adjustedPlace = { ...place };

    for (const otherPlace of adjustedPlaces) {
      const distance = haversineDistance(adjustedPlace.location.coordinates, otherPlace.location.coordinates);

      if (distance < minDistance) {
        const angle = Math.atan2(
          adjustedPlace.location.coordinates[0] - otherPlace.location.coordinates[0] + (Math.random() - 0.5) / 100,
          adjustedPlace.location.coordinates[1] - otherPlace.location.coordinates[1] + (Math.random() - 0.5) / 100
        );

        adjustedPlace.location.coordinates = [
          otherPlace.location.coordinates[0] +
            (((minDistance * Math.sin(angle)) / R) * (180 / Math.PI)) /
              Math.cos(otherPlace.location.coordinates[1] * (Math.PI / 180)),
          otherPlace.location.coordinates[1] + ((minDistance * Math.cos(angle)) / R) * (180 / Math.PI),
        ];
      }
    }

    adjustedPlaces.push(adjustedPlace);
  });

  return adjustedPlaces;
};
