import CharityShop from '../assets/images/charity-shop.svg';
import CompostingHub from '../assets/images/composting-hub.svg';
import ContainerDeposit from '../assets/images/container-deposit.svg';
import RecycleStation from '../assets/images/recycle-station.svg';
import './Key.css';

const Key = () => {
  return (
    <div className="key-container">
      <div className="key-item">
        <img src={RecycleStation} alt="Recycling location" />
        <span>Recycling location</span>
      </div>
      <div className="key-item">
        <img src={ContainerDeposit} alt="Container deposit" />
        <span>Container deposit</span>
      </div>
      <div className="key-item">
        <img src={CompostingHub} alt="Compost hub" />
        <span>Compost hub</span>
      </div>
      <div className="key-item">
        <img src={CharityShop} alt="Charity shop" />
        <span>Charity shop</span>
      </div>
    </div>
  );
};

export default Key;
