import cn from 'classnames';
import { FC, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import PlaceholderImage from '../assets/images/location-placeholder.png';
import PlaceholderIcon from '../assets/images/logo-gentle.svg';
import { Place } from '../types';
import './PlaceImage.css';

export type PlaceImageProps = HTMLAttributes<HTMLDivElement> & {
  place?: Place;
  transparent?: boolean;
};

const PLACEHOLDER_BLURHASH = 'L15h#@_NIA.8a}ayoft7%Mx]IUDi';

const PlaceImage: FC<PlaceImageProps> = ({ place, transparent, className, ...rest }) => {
  const [usePlaceholder, setUsePlaceholder] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const placeholderSource = useMemo(() => (transparent ? PlaceholderIcon : PlaceholderImage), [transparent]);
  const source = useMemo(
    () => (usePlaceholder ? placeholderSource : `${process.env.REACT_APP_MEDIA_URL}/place-images/${place?.id}.jpg`),
    [place, placeholderSource, usePlaceholder]
  );

  const onError = useCallback(() => {
    setUsePlaceholder(true);
  }, []);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <div className={cn('place-image-container', className)} {...rest}>
      <div className="place-image-blurhash-container">
        <Blurhash
          hash={place?.blurhash ?? PLACEHOLDER_BLURHASH}
          width="100%"
          height="100%"
          resolutionX={64}
          resolutionY={64}
        />
      </div>
      <img
        className={cn('place-image-image', loaded && 'image-loaded')}
        style={usePlaceholder && transparent ? { left: '-10px' } : undefined}
        src={source}
        alt={place?.name ?? 'Disposal location'}
        onError={onError}
        onLoad={onLoad}
      />
    </div>
  );
};

export default PlaceImage;
