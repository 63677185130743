import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Popup as MapPopup } from 'react-map-gl';
import { PlaceFeature } from '../types';
import './Popup.css';

export interface PopupProps {
  feature: PlaceFeature;
  onTitleClick?: (feature: PlaceFeature) => void;
  onMouseEnter?: () => void;
}

const Popup = ({ feature, onTitleClick, onMouseEnter }: PopupProps) => {
  const popupRef = useRef<mapboxgl.Popup>(null);

  const [tooltipText, setTooltipText] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [containerPosition, setContainerPosition] = useState({ x: 0, y: 0 });

  const getContainerPosition = useCallback(() => {
    const bounds = popupRef.current?.getElement().getBoundingClientRect();

    if (bounds) {
      setContainerPosition({ x: bounds.left, y: bounds.bottom });

      return { x: bounds.left, y: bounds.bottom };
    }

    return containerPosition;
  }, [containerPosition]);

  const onMouseOver: React.MouseEventHandler<HTMLImageElement> = useCallback(
    e => {
      const bounds = (e.target as HTMLImageElement).getBoundingClientRect();

      let position = containerPosition;

      // Ensure the container position is set
      if (!position.x && !position.y) {
        position = getContainerPosition();
      }

      setTooltipPosition({ x: bounds.x - position.x, y: bounds.y - position.y });
      setTooltipText(e.currentTarget.alt);
    },
    [containerPosition, getContainerPosition]
  );

  const images = useMemo(() => {
    if (!feature.properties.itemIcons) {
      return;
    }

    return feature.properties.itemIcons
      .split('|')
      .slice(0, 15)
      .map((data, index) => {
        const [name, source] = data.split('~');

        return (
          <img
            key={`${feature.properties.id}-${index}`}
            className="popup-image"
            src={source}
            alt={name}
            onMouseOver={onMouseOver}
            onMouseOut={() => setTooltipText('')}
          />
        );
      });
  }, [feature.properties.id, feature.properties.itemIcons, onMouseOver]);

  const handleTitleClick = useCallback(() => {
    onTitleClick?.(feature);
  }, [feature, onTitleClick]);

  useEffect(() => {
    const element = popupRef.current?.getElement();

    if (element) {
      getContainerPosition();

      if (onMouseEnter) {
        element.onmouseover = onMouseEnter;
      }
    }
  }, [getContainerPosition, onMouseEnter]);

  return (
    <MapPopup
      ref={popupRef}
      longitude={feature.geometry.coordinates[0]}
      latitude={feature.geometry.coordinates[1]}
      closeButton={false}
      offset={20}>
      <div className="popup-name" onClick={handleTitleClick}>
        {feature.properties.name}
      </div>
      {images}
      <div
        key={feature.id}
        className="popup-image-tooltip"
        style={{
          transform: `translate(calc(${tooltipPosition.x}px - 30%), ${tooltipPosition.y}px`,
          opacity: tooltipText ? 1 : 0,
        }}>
        <p>{tooltipText}</p>
      </div>
    </MapPopup>
  );
};

export default Popup;
